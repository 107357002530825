import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Content from "../Layout/Content"

import bundyBook from "./logos/bundy-book.png"
import bundyLocalBook from "./logos/bundy-local-book.png"
import touristQueensland from "./logos/tourist-queensland.png"

import "./index.scss"

const publicationsFiles = `${process.env.FILE_BUCKET}/publications/`

const Publications = ({ publications }) => {
  const getTestimonialLink = (id) => {
    if (id === "1") {
      return "/testimonials/the-bundy-book"
    }
    if (id === "2") {
      return "/testimonials/the-bundy-local"
    }
    if (id === "3") {
      return "/testimonials/tourist-queensland"
    }
  }

  const getPublicationImage = (id) => {
    if (id === "1") {
      return [bundyBook, "publication-title"]
    }
    if (id === "2") {
      return [bundyLocalBook, "publication-title"]
    }
    if (id === "3") {
      return [touristQueensland, "publication-title-tq"]
    }
  }

  return (
    <>
      <div className="row">
        <div className="col text-center publication-heading">
          <h1>Our Publications</h1>
          <p>Our publications are all available to read online</p>
        </div>
      </div>
      {publications.map((publication) => {
        let publicationTitleImage = getPublicationImage(publication.id)
        return (
          <div className="row publication" key={publication.id}>
            <div className="col-md-4">
              <img
                src={`${publicationsFiles}${publication.frontpage}`}
                alt={publication.title}
                className="publication-frontpage"
              />
            </div>
            <div className="col-md-8">
              <img
                src={publicationTitleImage[0]}
                alt={publication.title}
                className={publicationTitleImage[1]}
              />
              <br />
              <span className="publication-date">{publication.date}</span>
              <Content content={publication.description} />
              <ul className="nav">
                <li className="nav-item mr-2 mb-3">
                  <a
                    href={`${publicationsFiles}${publication.publication}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="primary" bsPrefix="cln">
                      Read Now
                    </Button>
                  </a>
                </li>
                <li className="nav-item mr-2 mb-3">
                  <Link to={"/contact"}>
                    <Button variant="primary" bsPrefix="cln">
                      Advertise Here
                    </Button>
                  </Link>
                </li>
                <li className="nav-item mr-2 mb-3">
                  <Link to={getTestimonialLink(publication.id)}>
                    <Button variant="primary" bsPrefix="cln">
                      Testimonials
                    </Button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Publications
